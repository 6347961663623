<template>
  <b-container fluid>

    <Dash
      url="wallet_monitoring"
    />
  </b-container>
</template>

<script>
import Dash from '../reports/components/Dash.vue';

export default {
    components: {
        Dash,
    },

};
</script>
